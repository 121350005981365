
import {
    routeToBusiness,
    routeToJob,
    routeToBusinessSite,
} from "@/helpers/routing"
import { mdiChevronUp, mdiClose, mdiChevronRight } from "@mdi/js"

import UserAvatar from "@/components/user/UserAvatar"
import BusinessLogo from "@/components/business/BusinessLogo"
import BusinessResponseTime from "@/components/business/BusinessResponseTime"
import OPicture from "@/components/base/image/OPicture"
import Card from "@/components/base/card/Card"
import Button from "@/components/base/Button"

export default {
    name: "BusinessCard",
    components: {
        OPicture,
        BusinessResponseTime,
        BusinessLogo,
        UserAvatar,
        Button,
        Card,
        Overlay: () => import("@/components/base/card/Overlay"),
    },
    icons: {
        mdiClose,
        mdiChevronUp,
        mdiChevronRight,
    },
    props: {
        /** business object */
        business: {
            default: null,
            type: Object,
        },
        /** fixed width class applied to the card */
        fixedWidth: {
            type: Boolean,
            default: false,
        },
        /** Disables routing to businessprofile on contactperson click, emits click:contactPerson */
        noContactPersonRouting: {
            type: Boolean,
            default: false,
        },
        /**
         * adds target blank to link (used for embeding cards in an iframe into another page)
         */
        targetBlank: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showJobsOverlay: false,
    }),
    computed: {
        cardKey() {
            return this.business?.business_site_slug || this.business.slug
        },
        location() {
            const pa = this.business.primary_address
            if (pa) {
                return `${pa.street}, ${pa.city}`
            }
            return "In der Nähe"
        },
        routeToBusiness() {
            let route
            if (this.business.is_site_management_enabled) {
                route = routeToBusinessSite(this.business)
            } else {
                route = routeToBusiness(this.business)
            }
            if (route) {
                const resolved = this.$router.resolve(route)
                if (resolved) return resolved.href
            }
            return ""
        },
        showContactPerson() {
            return !!this.business.public_contact_person
        },
        contactPersonName() {
            if (this.showContactPerson)
                return `${this.business.public_contact_person.firstname} ${this.business.public_contact_person.lastname}`
            else return ""
        },
        jobsContainerClasses() {
            return {
                "overflow-y-auto scrollbar--thin scrollbar--white py-2 pl-2 pr-1":
                    this.showScrollbar,
                "pa-2": !this.showScrollbar,
            }
        },
        showJobs() {
            return (
                Array.isArray(this.business.published_jobs) &&
                this.business.published_jobs.length > 0
            )
        },
        topJob() {
            if (!this.showJobs) return null
            return this.business.published_jobs[0]
        },
        numberOfOtherJobs() {
            if (!this.showJobs) return 0
            return this.business.published_jobs.length - 1
        },
    },
    methods: {
        onJobsElementClick() {
            // go to the job page, if business has only one job
            if (!this.numberOfOtherJobs) {
                this.onJobClick(this.topJob.slug)
                return
            }
            if (this.showJobsOverlay) return
            this.showJobsOverlay = true
        },
        onCloseBtnClick() {
            this.showJobsOverlay = false
        },
        onJobClick(jobSlug) {
            const route = routeToJob({
                business: this.business,
                slug: jobSlug,
            })
            if (this.targetBlank) {
                let routeData = this.$router.resolve(route)
                window.open(routeData.href, "_blank")
            } else {
                this.$router.push(route)
            }
        },
        onContactPersonClick() {
            if (this.noContactPersonRouting) {
                this.$emit("click:contactPerson")
                return
            }
            let route = routeToBusiness(this.business, true)

            if (this.targetBlank) {
                let routeData = this.$router.resolve(route)
                window.open(routeData.href, "_blank")
            } else {
                this.$router.push(route)
            }
        },
    },
}
