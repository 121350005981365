
import colors from "@/mixins/colors"
import { mdiAccountCircle } from "@mdi/js"
export default {
    name: "UserAvatar",
    mixins: [colors],
    props: {
        /** User OR ContactPerson object */
        user: {
            type: Object,
            required: true,
        },
        /** Avatar size */
        size: {
            type: Number,
            default: 48,
        },
        /** Makes the avatar a clickable link (default: to the applied user objects home) */
        clickable: {
            type: Boolean,
            default: false,
        },
        /** Classes being applied to the avatar element (which is not root!) */
        avatarClasses: {
            type: [String, Object],
            default: "",
        },
        /** Custom placeholder color */
        customColor: {
            type: String,
            default: null,
        },
        customLink: {
            type: [String, Object],
            default: "",
        },
    },
    data() {
        return {
            mdiAccountCircle,
        }
    },
    computed: {
        placeholderColor() {
            return (
                this.customColor ||
                this.stringToRGB(this.placeholderName, 0.6, 0.6)
            )
        },
        imageUrl() {
            return this.$getImage({
                image: this.userImage,
                preferedSize: "3",
            }).src
        },
        userImage() {
            if (!this.user) return null
            if (this.user.school && this.user.trainee === null) {
                /* Use school logo if available */
                return this.user.school.main_image
            }
            if (this.user.region) {
                /* Use region logo if available */
                return this.user.region.logo
            }
            return this.user.profile_picture
        },
        userRouting() {
            if (this.customLink) return this.customLink
            else if (this.user) {
                if (this.user.trainee) {
                    return { name: "i-profile" }
                }
                if (this.user.region) {
                    return { name: "region" }
                }
                if (this.user.school) {
                    return { name: "schule-einstellungen" }
                }
            }
            return ""
        },
        /** First letter of first and last word in user name / contact person name */
        placeholderName() {
            if (!this.user) return ""
            let name = this.user.display_name || this.user.name
            if (!name) {
                /* ContactPerson Object */
                name = `${this.user.firstname} ${this.user.lastname}`
            }
            if (!name) return ""
            const words = name.split(" ")
            let first = words.shift() || ""
            let last = words.pop() || ""
            const specialCharacters = /[^a-zA-ZüöäÜÖÄß]/g
            first = first.replace(specialCharacters, "")
            last = last.replace(specialCharacters, "")
            return first.charAt(0) + last.charAt(0)
        },
    },
}
