
import mappingService from "@/services/mapping.service"

export default {
    name: "BusinessResponseTime",
    props: {
        /** Time of response in seconds */
        time: {
            type: Number,
            required: true,
        },
        /**
         * Displays a minimal version of the response time (text only)
         */
        minimal: {
            type: Boolean,
            default: false,
        },
        /** Text color in HEX format */
        color: {
            type: String,
            default: null,
        },
    },
    computed: {
        timeHours() {
            return mappingService.mapBusinessResponseTime(this.time)
        },
        colorClass() {
            if (this.color)
                return {
                    color: this.color,
                }
            let oneHour = 60 * 60
            let tenMins = 10 * 60
            if (this.time < 12 * oneHour + tenMins) {
                return "success--text"
            }
            return "warning--text"
        },
    },
}
